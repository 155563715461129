@import "../../sass/colors";
@import "../../sass/mixins";

.tabs-container {
  .tabs-buttons {
    button {
      font-size: 0.9em;

      margin-left: 0.25em;
      margin-right: 0.25em;

      background-color: transparent;
      border: 1px solid $color-grey-light;
      padding: 0.2em 0.75em;
      @include border-radius;

      &.active {
        background-color: $color-primary;
        color: $color-white;
        border-color: $color-primary;
      }
    }

    &.text-left {
      button:first-child {
        margin-left: 0;
      }
    }

    &.text-right {
      button:last-child {
        margin-right: 0;
      }
    }
  }
  .tabs-content {
    margin-top: -1px;
    padding-top: 1em;
  }
}
