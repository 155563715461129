@import "../../sass/fontawesome";

@mixin icon-solid($type) {
  @extend %fa-icon;
  @extend .fas;

  &::after {
    content: fa-content($type);
  }
}

@mixin icon-regular($type) {
  @extend %fa-icon;
  @extend .far;

  &::after {
    content: fa-content($type);
  }
}

.icon {
  &.size-2 {
    font-size: 2em;
  }
  &.size-3 {
    font-size: 3em;
  }
  &.size-4 {
    font-size: 4em;
  }
  &.size-5 {
    font-size: 5em;
  }
}

.trophy {
  @include icon-solid($fa-var-trophy);
}

.save {
  @include icon-regular($fa-var-save);
}

.calendar {
  @include icon-regular($fa-var-calendar-alt);
}

.trash {
  @include icon-regular($fa-var-trash-alt);
}

.flag {
  @include icon-regular($fa-var-flag);
}

.coins {
  @include icon-solid($fa-var-coins);
}

.cookie-bite {
  @include icon-solid($fa-var-cookie-bite);
}

.ad {
  @include icon-solid($fa-var-ad);
}

.location {
  @include icon-solid($fa-var-map-marker-alt);
}

.eye {
  @include icon-regular($fa-var-eye);
}

.eye-slash {
  @include icon-regular($fa-var-eye-slash);
}

.icon.loading {
  @include icon-solid($fa-var-spinner);
}

.icon.home {
  @include icon-solid($fa-var-home);
}

.icon.search {
  @include icon-solid($fa-var-search);
}

.icon.key {
  @include icon-solid($fa-var-key);
}

.icon.user {
  @include icon-solid($fa-var-user-circle);
}

.icon.save {
  @include icon-solid($fa-var-save);
}

.icon.reset {
  @include icon-solid($fa-var-undo);
}

.icon.signout {
  @include icon-solid($fa-var-sign-out-alt);
}

.icon.pen {
  @include icon-solid($fa-var-pen);
}

.icon.error {
  @include icon-solid($fa-var-exclamation-triangle);
}

.icon.cross {
  @include icon-solid($fa-var-times);
}

.icon.spinner {
  @include icon-solid($fa-var-spinner);
}

.icon.bars {
  @include icon-solid($fa-var-bars);
}

.icon.thumbs-up {
  @include icon-solid($fa-var-thumbs-up);
}

.icon.info {
  @include icon-solid($fa-var-info);
}

.icon.circle,
.radio-off {
  @include icon-regular($fa-var-circle);
}

.icon.dot-circle,
.radio-on {
  @include icon-regular($fa-var-dot-circle);
}

.icon.square {
  @include icon-regular($fa-var-square);
}

.icon.check-square {
  @include icon-regular($fa-var-check-square);
}

.icon.check-circle {
  @include icon-regular($fa-var-check-circle);
}

.icon.clipboard {
  @include icon-regular($fa-var-clipboard);
}

.icon.angle-down {
  @include icon-solid($fa-var-angle-down);
}

.icon.stream {
  @include icon-solid($fa-var-stream);
}

.icon.tasks {
  @include icon-solid($fa-var-tasks);
}

.icon.plus-square {
  @include icon-regular($fa-var-plus-square);
}

.icon.envelope {
  @include icon-regular($fa-var-envelope);
}

.icon.sticky-note {
  @include icon-regular($fa-var-sticky-note);
}

.icon.handshake {
  @include icon-regular($fa-var-handshake);
}

.icon.smile {
  @include icon-regular($fa-var-smile);
}

.icon.comment {
  @include icon-regular($fa-var-comment);
}

.icon.phone {
  @include icon-solid($fa-var-phone);
}

.icon.voicemail {
  @include icon-solid($fa-var-voicemail);
}

.icon.toggle-on {
  @include icon-solid($fa-var-toggle-on);
}

.icon.toggle-off {
  @include icon-solid($fa-var-toggle-off);
}

.spin {
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.icon.click:hover {
  cursor: pointer;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
