@import "../../sass/colors";
@import "../../sass/fontawesome";
@import "../../sass/layout";

.register-interest {
  padding: 2em 0;

  @include lg-screen {
    padding: 3em 0;
  }

  ul {
    list-style: none;
    padding: 0;

    font-size: 1.1em;
    li {
      margin-bottom: 0.25em;

      &::before {
        @extend %fa-icon;
        @extend .far;

        content: fa-content($fa-var-check-circle);
        margin-right: 0.5em;
        color: $color-green;
      }
    }
  }
}
