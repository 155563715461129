@import "../../sass/layout";

.search-form {
  margin-top: -0.5rem;

  @include md-screen {
    margin-top: -0.75rem;
  }

  @include lg-screen {
    margin-top: -1rem;
  }

  @include xl-screen {
    margin-top: -1.25rem;
  }
}
