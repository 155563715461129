@import "../../sass/colors";
@import "../../sass/typography";

.stat-box {
  padding: 0;

  .title {
    margin: 0;
    background-color: $color-primary;
    color: $color-white;
    padding: 0.5em;
  }

  #loading-spinner {
    font-size: 1em;
    color: $color-primary;
  }

  .content {
    font-size: 2.5em;
    font-family: $font-title;
    font-weight: bold;
    text-align: center;
  }
}
