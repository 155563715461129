#search-route {
  grid-column-start: 1;
  grid-column-end: 13;

  .search-jobs {
    margin-bottom: 2em;
    display: flex;

    .form-group {
      margin-bottom: 0;
    }

    .btn {
      align-self: flex-end;
    }
  }
}