@import "../../sass/colors";
@import "../../sass/helpers";
@import "../../sass/layout";
@import "../../sass/mixins";

#app-nav {
  .icon.bars,
  .icon.cross {
    font-size: 1.75em;

    position: absolute;
    top: 0.35em;
    right: 0.5em;

    @include md-screen {
      display: none;
    }
  }

  .icon.cross {
    font-size: 1.8em;
    right: 0.55em;
  }

  .navbar {
    position: absolute;
    z-index: 100;
    top: 2.95em;
    right: 0;
    left: 0;

    margin: 0;
    padding: 0.25em 0;

    list-style: none;

    transition: all 0.2s ease-out;
    transform: scale(1, 0);
    transform-origin: top;

    background-color: $color-white;

    @include md-screen {
      position: relative;
      top: auto;
      right: auto;

      transform: scale(1);

      background-color: transparent;
    }

    &.open {
      transform: scale(1);

      border-bottom: 1px solid $color-grey-light;

      @include md-screen {
        border-bottom: 0;
        border-radius: 0;
      }
    }
  }
}
