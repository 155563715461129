@import "../../sass/colors";
@import "../../sass/layout";

.modal-container {
  position: fixed;
  z-index: 200;

  display: flex;

  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.75);

  align-items: center;
  justify-content: center;

  .card {
    position: relative;
    width: 25em;
    max-width: 80vw;

    background-color: $color-white;
    border: 0;
    padding: 0;

    h3.title {
      background-color: $color-primary;
      color: $color-white;
      padding: 0.75em 1em;
      margin-bottom: 0;
    }

    .content {
      padding: 1em;

      max-height: 80vh;
      overflow: auto;
    }

    @include md-screen {
      width: 30em;
      height: auto;
    }

    .icon.cross {
      font-size: 1.4em;

      position: absolute;
      top: 0.8rem;
      right: 0.75rem;

      color: $color-white;

      &:hover {
        cursor: pointer;
      }

      @include md-screen {
        right: 1em;
        top: 0.6em;
      }
    }
  }
}
