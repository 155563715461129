@import "./sass/colors";
@import "./sass/layout";
@import "./sass/typography";

html {
  height: 100%;
  padding: 0;
}

body {
  font-family: $font-regular;

  min-height: 100%;
  margin: 0;

  color: $color-text;
}

#root {
  min-height: 100vh;
}

img {
  width: 100%;
}

a,
a:visited,
a:focus {
  color: $color-primary;
  text-decoration: none;
}
