@import "../../sass/colors";
@import "../../sass/helpers";
@import "../../sass/mixins";

.react-datepicker-wrapper {
  input {
    &.validation-error {
      border-color: $color-red;
    }

    &:disabled {
      padding-left: 0;
      border: 0;
    }
  }
}
