@import "./sass/helpers";
@import "./sass/typography";
@import "./sass/colors";
@import "./sass/layout";
@import "./sass/mixins";

.screen-size {
  position: fixed;
  z-index: 1000;
  right: 0;
  bottom: 0;

  padding: 0.5em;

  opacity: 0.75;
  color: $color-white;
  background-color: $color-primary;
}

#app {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

// .app-container {
//   font-size: 16px;

//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;

//   height: 100%;
//   margin-bottom: 2em;
//   padding-bottom: 2em;

//   color: $color-text;

//   #header-container {
//     position: relative;

//     display: flex;
//     flex-direction: column;

//     margin-bottom: 0.5em;
//     padding: em(9px) 0;

//     background-color: $color-white;

//     @include md-screen {
//       align-items: center;
//       flex-direction: row;
//       justify-content: space-between;

//       margin-bottom: 1.5em;
//       padding: em(10px);

//       border-bottom: 1px solid lightgrey;

//       @include box-shadow;
//     }
//   }

//   #app-content {
//     width: 95%;
//     margin-right: auto;
//     margin-left: auto;

//     @include md-screen {
//       max-width: 700px;
//     }
//     @include lg-screen {
//       max-width: 1000px;
//     }
//     @include xl-screen {
//       max-width: 1200px;
//     }
//   }
// }
