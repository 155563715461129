@import "../../sass/colors";
@import "../../sass/layout";
@import "../../sass/typography";

.profile-job-stat {
  font-family: $font-title;
  font-size: 2.5em;
  line-height: 1em;
  text-align: center;

  @include md-screen {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translateY(-20%) translateX(-50%);
    font-size: 3em;
  }

  @include lg-screen {
    font-size: 5em;
  }

  &.rejected {
    color: $color-red;
  }

  &.accepted {
    color: $color-green;
  }
}
