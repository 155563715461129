@import "../../sass/colors";
@import "../../sass/helpers";
@import "../../sass/mixins";
@import "../../sass/layout";

button {
  outline: none;

  &:hover {
    cursor: pointer;
  }
}

.button {
  font-size: 1rem;

  position: relative;

  padding: 0.375rem 1rem;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  border: em(1px) solid transparent;

  &:not(:only-child) {
    &:not(:last-of-type) {
      margin-right: .5em;
    }
  }

  // &:last-of-type {
  //   border: solid 3px black;
  // }

  @include border-radius;
  @include md-screen {
    padding: 0.375rem 2rem;
  }

  &.block {
    width: 100%;
    padding: 0.5em 2em;
  }

  &:disabled {
    opacity: 0.5;
  }

  &.small {
    font-size: 0.8rem;

    padding: 0.175rem 1rem;
  }

  &.primary {
    &.color-primary {
      color: $color-white;
      background-color: $color-primary;

      &:hover {
        background-color: darken($color-primary, 10%);
      }
    }

    &.color-secondary {
      background-color: $color-secondary;

      &:hover {
        background-color: darken($color-secondary, 10%);
      }
    }

    &.color-red {
      color: $color-white;
      background-color: $color-red;

      &:hover {
        background-color: darken($color-red, 10%);
      }
    }
  }

  &.secondary {
    border: em(1px) solid;
    background-color: transparent;

    &.color-primary {
      color: $color-primary;
      border-color: $color-primary;
    }

    &.color-secondary {
      color: $color-secondary;
      border-color: $color-secondary;
    }

    &.color-red {
      color: $color-red;
      border-color: $color-red;
    }
  }

  &.text {
    text-transform: none;

    color: $color-primary;
    background-color: transparent;

    &:hover {
      text-decoration: underline;
    }

    &.inline {
      padding: 0;
      text-align: left;
    }

    &.color-secondary {
      color: $color-secondary;
    }

    &.color-red {
      color: $color-red;
    }
  }

  .label {
    position: relative;
  }

  .loading {
    position: absolute;

    margin-top: 0.05em;
    margin-left: -0.7em;
  }

  .progress {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;

    background-color: darken($color-primary, 20%);

    transition: width 0.2s ease-out;
  }
}
