$color-black: #000;
$color-grey-dark: #444;
$color-grey: #888;
$color-grey-light: #ccc;
$color-white: #fff;
$color-red: #ad0023;

$color-green: #00ad2e;

$color-primary: #059dff;
$color-secondary: #3ff40b;

$color-primary-dark: darken($color-primary, 20%);
$color-primary-light: lighten($color-primary, 20%);

$color-text: #212529;
$color-text-secondary: $color-grey;
