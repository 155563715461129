@import "../../sass/colors";
@import "../../sass/mixins";
@import "../../sass/helpers";

textarea {
  font-size: 1rem;

  margin-bottom: 0.25em;
  padding: em(5px);

  border: em(1px) solid $color-grey-light;
  @include border-radius;

  outline-width: 0;

  &.validation-error {
    border-color: $color-red;
  }

  &:disabled {
    padding-left: 0;
    border: 0;
  }
}
