@import "../../sass/colors";
@import "../../sass/mixins";
@import "../../sass/layout";

.card {
  overflow: visible !important;

  padding: 0.75rem;
  border: 1px solid lightgrey;
  @include border-radius;

  h3 {
    margin-bottom: 0.75em;

    .title-icon {
      margin-right: 0.5em;
    }
  }

  @include md-screen {
    padding: 1em;
    background-color: $color-white;
  }

  @include lg-screen {
    padding: 1.5em;
  }
}
