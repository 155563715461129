@import "../../sass/colors";
@import "../../sass/helpers";
@import "../../sass/mixins";

.field-group {
  display: flex;
  justify-content: stretch;

  input {
    width: 100%;
  }

  .icon {
    @include border-radius;
  }

  &.password {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 2em;
      margin-bottom: 0.25em;

      color: $color-grey-dark;
      border: em(1px) solid $color-grey-light;
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

// Stop MS Edge displaying password reveal
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
