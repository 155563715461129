@import "../../sass/colors";
@import "../../sass/mixins";
@import "../../sass/layout";

.result-item-wrapper {
  position: relative;

  overflow: hidden;

  border-bottom: 1px dashed $color-grey-light;

  &.desktop {
    @include lg-screen {
      border: 1px solid $color-grey-light;
      padding: 0.75em;
      @include border-radius();

      .result-item {
        padding: 0;

        .ad-link .label {
          font-size: 1.25em !important;
          line-height: 1em;
        }

        .details {
          grid-row-gap: 0.5em !important;

          @include xl-screen {
            grid-row-gap: 0.75em !important;
          }
        }
      }
    }
  }

  &:first-child {
    .result-item {
      padding-top: 0;
    }
  }

  .save-icon,
  .remove-icon {
    position: absolute;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 100px;
    height: 100%;

    color: $color-white;
  }

  .save-icon {
    left: 0;

    background-color: $color-green;
  }

  .remove-icon {
    right: 0;

    background-color: $color-red;
  }

  .result-item {
    position: relative;

    min-width: 100%;
    max-width: 100%;
    padding: 0.75em 0;

    background-color: $color-white;

    .details {
      color: $color-text;
    }
  }
}
