@import "../../sass/colors";

.reject-toggle {
  color: $color-text-secondary;
  position: relative;

  span {
    top: -2px;
    position: relative;
  }

  .icon {
    color: $color-primary;
    font-size: 1.3em;
  }
}
