@import '../../sass/layout';

#login-container {
  grid-column-start: 1;
  grid-column-end: 13;

  @include md-screen {
    grid-column-start: 4;
    grid-column-end: 10;
  }

  @include lg-screen {
    grid-column-start: 5;
    grid-column-end: 9;
  }
}