@import "../../sass/mixins";
@import "../../sass/colors";
@import "../../sass/helpers";

.select-container {
  position: relative;

  .select-value {
    position: relative;

    &:hover {
      cursor: pointer;
    }

    .icon {
      font-size: 1.5em;

      position: absolute;
      top: em(2px);
      right: em(10px);

      transition: transform 0.2s ease-out;

      &.open {
        transform: rotate(180deg);
      }
    }

    &.validation-error {
      border-color: $color-red;
    }
  }

  .select-options {
    position: absolute;
    z-index: 1000;

    overflow: hidden;

    width: 100%;
    height: auto;
    padding: 0.5em 0;

    transition: all 0.2s ease-out;
    transform: scaleY(1);
    transform-origin: top;

    border: em(1px) solid $color-grey-light;
    background-color: $color-white;

    @include border-radius;

    &:hover {
      cursor: pointer;
    }

    &.closed {
      transform: scale(1, 0);
    }

    .select-option {
      padding: 0.25em 0.5em;

      &:hover {
        background-color: lighten($color-primary, 40%);
      }
    }
  }
}
