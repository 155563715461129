.new-history-form {
  .date-type-container {
    display: flex;
    justify-content: space-between;

    .input-group {
      width: 100%;
    }
  }
}
