@import "../../sass/colors";

.swipe {
  background-image: linear-gradient(
    to right,
    $color-red 2.5em,
    $color-white 20% 80%,
    $color-green calc(100% - 2.5em)
  );
  border-radius: 3em;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  height: 2em;
  font-size: 0.75em;
  align-items: center;

  .icon {
    width: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .icon.save {
    background-color: $color-green;
    color: $color-white;
  }

  .icon.trash {
    background-color: $color-red;
    color: $color-white;
  }
}
