$small-screen-width: 425px;
$medium-screen-width: 767px;
$large-screen-width: 992px;
$extra-large-screen-width: 1200px;

@mixin sm-screen {
  @media (min-width: #{$small-screen-width}) {
    @content;
  }
}

@mixin md-screen {
  @media (min-width: #{$medium-screen-width}) {
    @content;
  }
}

@mixin lg-screen {
  @media (min-width: #{$large-screen-width}) {
    @content;
  }
}

@mixin xl-screen {
  @media (min-width: #{$extra-large-screen-width}) {
    @content;
  }
}

// Create Grid Classes
@for $i from 1 through 12 {
  @for $x from 1 through 12 {
    .grid-#{$i}-#{$x} {
      display: grid;
      grid-template-columns: repeat(#{$i}, 1fr);
      grid-template-rows: repeat(#{$x}, auto);
      grid-column-gap: 0.5rem;
      grid-row-gap: 0.5rem;

      @include md-screen {
        grid-column-gap: 0.75rem;
        grid-row-gap: 0.75rem;
      }

      @include lg-screen {
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
      }

      @include xl-screen {
        grid-column-gap: 1.25rem;
        grid-row-gap: 1.25rem;
      }
    }
  }
}

.row-gap-0 {
  grid-row-gap: 0;
}

.column-gap-0 {
  grid-column-gap: 0;
}

$screens: "" "sm" "md" "lg" "xl";
$screen-sizes: 0px $small-screen-width $medium-screen-width $large-screen-width
  $extra-large-screen-width;

@function size-class($size) {
  @if $size == "" {
    @return "";
  }

  @return "-#{$size}";
}

@for $s from 1 through length($screens) {
  $screen: nth($screens, $s);
  $size: nth($screen-sizes, $s);

  @media only screen and (min-width: #{$size}) {
    @for $i from 1 through 12 {
      @for $x from 1 through 12 {
        @if $x >= $i {
          .col#{size-class($screen)}-#{$i}-#{$x} {
            grid-column-start: #{$i};
            grid-column-end: #{$x + 1};
          }
          .row#{size-class($screen)}-#{$i}-#{$x} {
            grid-row-start: #{$i};
            grid-row-end: #{$x + 1};
          }
          .col#{size-class($screen)}-#{$i} {
            grid-column-start: span #{$i};

            & > *:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@for $s from 1 through length($screens) {
  $screen: nth($screens, $s);
  $size: nth($screen-sizes, $s);

  .hide-#{$screen}-up {
    @media only screen and (min-width: #{$size}) {
      display: none !important;
    }
  }
  .hide-#{$screen}-down {
    @media only screen and (max-width: #{$size}) {
      display: none !important;
    }
  }
}

$positions: (
  "start": start,
  "center": center,
  "end": end,
  "stretch": stretch,
);

@each $name, $position in $positions {
  .align-self-#{$name} {
    align-self: $position;
  }

  .justify-self-#{$name} {
    justify-self: $position;
  }
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.container {
  width: 95%;
  margin: 0 auto;

  @include md-screen {
    width: 720px;
  }

  @include lg-screen {
    width: 960px;
  }

  @include xl-screen {
    width: 1140px;
  }
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}
