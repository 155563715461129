@import url("https://fonts.googleapis.com/css?family=Saira:400,600,800&display=swap");
@import "./colors";

$font-regular: sans-serif;
$font-title: "Saira", sans-serif;

.font-saira,
.font-title {
  font-family: "Saira", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-title;
  margin-top: 0;
  line-height: 1.1em;
}

h1,
h2 {
  margin: 0 0;
}

p {
  margin-top: 0;
  line-height: 1.4em;
}

.text-title {
  font-family: $font-title;
}

.text-semi-bold {
  font-weight: 600;
}

.text-bold {
  font-weight: 800;
}

.text-muted {
  color: $color-text-secondary;
}

.text-italic {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-right {
  text-align: right;
}

.text-color-primary {
  color: $color-primary;
}

.text-color-secondary {
  color: $color-secondary;
}

.text-color-red {
  color: $color-red;
}

.text-color-green {
  color: $color-green;
}
