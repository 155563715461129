@import "../../sass/colors";

.gdpr-content {
  table {
    margin-bottom: 1em;

    border-collapse: collapse;

    border: 1px solid $color-grey-light;

    td {
      padding: 0.75em;

      vertical-align: top;

      border: 1px solid $color-grey-light;
    }
  }
}
