@import "../../sass/colors";

.page-title {
  h2 {
  }

  p {
    margin: 0;
    margin-bottom: 0.5em;
  }
}
