@import "../../sass/colors";

.task-complete-form {
  p {
    margin-bottom: 0;
  }

  .job-status,
  .new-task {
    position: relative;

    h4 {
      margin-bottom: 0;
    }

    .toggle {
      position: absolute;
      top: 0.2em;
      font-size: 1.2em;
      right: 0;
      color: $color-primary;
    }
  }
}
