@import "../../sass/colors";
@import "../../sass/helpers";
@import "../../sass/layout";

#message-container {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 210;

  .message {
    margin-top: 1.5em;
    color: lighten($color-primary, 10%);
    border-color: lighten($color-primary, 10%);
    background-color: lighten($color-primary, 40%);
    min-width: 80vw;
    padding: 0.75em;

    @include md-screen {
      min-width: 50vw;
    }

    @include lg-screen {
      min-width: 30vw;
    }

    &.SUCCESS {
      color: $color-green;
      border-color: $color-green;
      background-color: lighten($color-green, 55%);
    }

    &.ERROR {
      color: $color-red;
      border-color: $color-red;
      background-color: lighten($color-red, 55%);
    }

    .icon.cross {
      float: right;

      margin-left: auto;
      padding-left: 1em;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
