@import "../../sass/layout";
@import "../../sass/colors";

#loading {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  height: 100vh;

  color: $color-primary;

  @include md-screen {
    font-size: 1.5em;
  }
  @include lg-screen {
    font-size: 2em;
  }
}

#loading-spinner {
  font-size: 3em;

  margin-bottom: 0.25em;

  -webkit-animation-name: spin;
  -moz-animation-name: spin;
  -ms-animation-name: spin;
  animation-name: spin;
  -webkit-animation-duration: 2000ms;
  -moz-animation-duration: 2000ms;
  -ms-animation-duration: 2000ms;
  animation-duration: 2000ms;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
