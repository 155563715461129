@import "../../sass/colors";
@import "../../sass/mixins";
@import "../../sass/layout";

.app-section {
  padding: 1em 0;

  &.background- {
    &primary {
      background-color: lighten($color-primary, 30%);
    }

    &secondary {
      background-color: lighten($color-secondary, 30%);
    }

    &grey {
      background-color: lighten($color-grey-light, 15%);
    }
  }

  &.slant- {
    &left {
      @include angle(both, true);
    }

    &left-top {
      @include angle(before, true);
    }

    &left-bottom {
      @include angle(after, true);
    }

    &right {
      @include angle(both);
    }

    &right-top {
      @include angle(before);
    }

    &right-bottom {
      @include angle(after);
    }
  }

  &.flush-top {
    margin-top: -0.5rem;

    @include md-screen {
      margin-top: -0.75rem;
    }

    @include lg-screen {
      margin-top: -1rem;
    }

    @include xl-screen {
      margin-top: -1.25rem;
    }
  }

  &.flush-bottom {
    margin-bottom: -0.5rem;

    @include md-screen {
      margin-bottom: -0.75rem;
    }

    @include lg-screen {
      margin-bottom: -1rem;
    }

    @include xl-screen {
      margin-bottom: -1.25rem;
    }
  }

  & > section > :last-child {
    margin-bottom: 0;
  }
}
