@import "../../sass/helpers";
@import "../../sass/typography";
@import "../../sass/colors";
@import "../../sass/mixins";

.input-group {
  display: flex;
  flex-direction: column;

  label {
    font-family: $font-title;

    &.required {
      &:after {
        content: " *";
        color: $color-red;
      }
    }
  }

  input,
  textarea,
  .select-value {
    font-family: $font-regular;
    font-size: 1em;

    margin-bottom: 0.25em;
    padding: em(5px);

    color: $color-text;
    border: em(1px) solid $color-grey-light;
    outline-width: 0;
    background-color: $color-white;

    @include border-radius;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus {
    font-size: 1em !important;

    border: em(1px) solid $color-grey-light;
    background-color: $color-white !important;

    -webkit-text-fill-color: $color-text;
  }

  input:disabled {
    color: $color-text-secondary;
    border-right: 0;
    background-color: lighten($color-grey-light, 10%);
  }

  .icon.disabled {
    color: $color-text-secondary;
    background-color: lighten($color-grey-light, 10%);
    &:after {
      content: "";
    }
  }
}
