@import "../../sass/typography";
@import "../../sass/fontawesome";

@mixin list {
  margin-bottom: 0.5em;

  list-style: none;
  padding-left: 1em;

  li {
    padding-bottom: 0.25em;

    &:before {
      @extend %fa-icon;
      @extend .fas;
      @extend .text-muted;

      padding-right: 0.5em;
    }
  }
}

@mixin list-title {
  @extend .text-title;

  display: block;
  font-weight: bold;
  margin-bottom: 0.25em;
  margin-left: -1em;
}

#change-log {
  .change {
    .change-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1em;

      .change-title {
        margin-bottom: 0;
      }

      .change-release {
        @extend .text-muted;

        &:before {
          content: "released: ";
        }
      }
    }

    .change-features {
      @include list;

      &:before {
        @include list-title;

        content: "New Features";
      }

      li {
        &:before {
          content: fa-content($fa-var-star);
        }
      }
    }

    .change-enhancements {
      @include list;

      &:before {
        @include list-title;

        content: "Enhancements";
      }

      li {
        &:before {
          content: fa-content($fa-var-wrench);
        }
      }
    }

    .change-bugs {
      @include list;

      &:before {
        @include list-title;

        content: "Bugs Fixed";
      }

      li {
        &:before {
          content: fa-content($fa-var-bug);
        }
      }
    }
  }
}
