@import "../../sass/layout";

.app-video.home-video {
  iframe {
    width: 290px;
    height: 163px;

    @include md-screen {
      width: 460px;
      height: 250px;
    }

    @include lg-screen {
      width: 600px;
      height: 338px;
    }
  }
}
