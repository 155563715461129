@import "../../sass/colors";
@import "../../sass/typography";

.footer {
  section {
    color: $color-primary-dark;

    .title {
      font-size: 1.15em;
      font-weight: bold;
      display: block;
      font-family: $font-title;
      margin-bottom: 0.15em;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        line-height: 1.4em;
      }
    }

    a,
    a:visited,
    button.text {
      color: $color-primary-dark;
    }
  }
}
