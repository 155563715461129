@import "../../sass/colors";
@import "../../sass/mixins";

.dashboard {
  .welcome-message {
    position: relative;
    display: block;
    white-space: pre-wrap;

    font-weight: bold;

    background-color: lighten($color-primary, 40%);
    border: 1px solid $color-primary;
    padding: 0.75em;

    @include border-radius();

    .icon {
      position: absolute;
      right: 0.75em;
      color: $color-text-secondary;
    }
  }
}
