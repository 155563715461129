@import "../../sass/colors";
@import "../../sass/colors";
@import "../../sass/layout";

.job-details-info {
  .activity-title {
    margin: 1em 0;
    color: $color-primary;
    border-bottom: 2px solid $color-primary;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-bottom: 0.75em;

    @include md-screen {
      margin: 0.5em 0;
    }

    h3 {
      margin-bottom: 0;
    }
  }
}
