@import "../../sass/colors";
@import "../../sass/layout";
@import "../../sass/mixins";

.task-list-container {
  .task-list-item {
    border-bottom: 1px dashed $color-grey-light;
    padding: 0.75em 0;
    white-space: pre-wrap;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }

    span.overdue {
      display: none;
    }

    &.overdue {
      color: $color-red;
      button {
        color: $color-red;
      }

      span.overdue {
        display: inline;
        font-size: 0.8em;
        margin-left: 0.75em;
      }
    }

    .task-complete {
      font-size: 1.25em;
      color: $color-text-secondary;
    }
  }
}
