@import "../../sass/colors";
.radio-group {
  grid-row-gap: 0 !important;

  .radio-item {
    position: relative;

    input {
      position: absolute;

      width: 0;
      height: 0;

      cursor: pointer;

      opacity: 0;
    }

    .radio-disabled {
      color: $color-text-secondary;

      .icon {
        color: $color-text-secondary;
      }
    }

    .icon {
      margin-right: 0.5em;

      color: $color-primary;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
