@import "../../sass/colors";
@import "../../sass/mixins";

.app-video {
  iframe {
    border: 3px solid $color-primary;
    padding: 0;
    @include border-radius();
  }
}
