@import "../../sass/colors";
@import "../../sass/mixins";
@import "../../sass/layout";

.cookie-banner {
  position: fixed;
  z-index: 101;

  width: 100%;
  padding: 1em 0;

  border-bottom: 2px solid $color-grey;
  background-color: lighten($color-grey-light, 15%);

  h4 {
    margin-bottom: 0.75em;
  }

  .content {
    display: flex;
    flex-direction: column;

    @include md-screen {
      flex-direction: row;
      justify-content: space-between;
    }

    p {
      font-size: 0.8em;

      @include md-screen {
        margin-bottom: 0;

        width: 75%;
      }

      button {
        font-size: 1em;
      }
    }

    button.primary {
      padding-left: 0.5em;
      padding-right: 0.5em;

      @include md-screen {
        align-self: center;
        justify-self: end;
      }
    }
  }
}
