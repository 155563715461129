@import "../../sass/colors";

.loading-spinner {
  display: flex;
  align-items: center;

  #loading-spinner {
    margin-right: 0.5em;
    color: $color-primary;
    font-size: 1.25em;
  }
}
