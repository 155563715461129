.button-group {
  .button {
    &:not(:first-child):not(:last-child) {
      border-radius: 0;
      border-left: 0;
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    }
  }
}
