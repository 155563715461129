@import "../../sass/layout";
@import "../../sass/typography";
@import "../../sass/colors";

.job-list-container {
  .job-list-item {
    border-bottom: 1px dashed grey;
    padding: 0.5em 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: 0;
    }

    &.header {
      font-family: $font-title;
      font-size: 1.1em;
      font-weight: bold;
    }

    .updated {
      // text-transform: capitalize;
      color: $color-text-secondary;
      font-size: 0.8em;

      @include md-screen {
        text-align: right;
      }
    }
  }
}
