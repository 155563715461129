@import "./colors";
@import "./layout";

@mixin box-shadow {
  box-shadow: 0px 0px 20px -10px $color-grey;
}

@mixin border-radius($radius: 0.2) {
  border-radius: #{$radius}em;
  overflow: hidden;
}

// Angled sections
@mixin angle($pseudo, $flip: false, $angle: 50px) {
  padding: 1em 0;

  @include md-screen {
    padding: calc(#{$angle} + 1em) 0;
  }

  $clip-one: 0 0;
  $clip-two: 100% #{$angle};
  $clip-three: 100% calc(100% - #{$angle});
  $clip-four: 0 100%;

  @if ($pseudo == "before") {
    $clip-three: 100% 100%;
    padding-bottom: 1em !important;
  }

  @if ($pseudo == "after") {
    $clip-two: 100% 0;
    padding-top: 1em !important;
  }

  @if ($flip) {
    $clip-one: 0 #{$angle};
    $clip-two: 100% 0;
    $clip-three: 100% 100%;
    $clip-four: 0 calc(100% - #{$angle});

    @if ($pseudo == "before") {
      $clip-four: 0 100%;
      padding-bottom: 1em !important;
    }

    @if ($pseudo == "after") {
      $clip-one: 0 0;
      padding-top: 1em !important;
    }
  }

  @include md-screen {
    clip-path: polygon($clip-one, $clip-two, $clip-three, $clip-four);
  }
}
