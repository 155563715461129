@import "../../../sass/colors";
@import "../../../sass/helpers";
@import "../../../sass/layout";

.nav-link {
  border-bottom: 1px dashed $color-grey-light;

  &:last-child {
    border-bottom: 0;
  }

  @include md-screen {
    display: inline;

    border-bottom: 0;
  }

  .icon {
    width: 1.25em;
    text-align: center;

    @include md-screen {
      width: auto;
    }
  }

  a {
    font-size: 1.25em;

    display: block;

    padding: 0.25em 0.5em;

    transition: all 0.2s ease-out;
    text-decoration: none;

    color: $color-text;

    @include md-screen {
      display: inline;
      font-size: 1em;

      margin-left: 0.75em;
      padding: 0;

      color: $color-white;
      border-bottom: 1px dashed transparent;

      &.active {
        border-bottom: 1px dashed $color-white;
      }
    }
    @include lg-screen {
      font-size: 1.15em;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
