@import "../../sass/layout";

.hero-section {
  padding: 1.5em 0;

  @include lg-screen {
    padding: 2em 0;
  }

  h2 {
    text-align: center;
    font-size: 2.8em;
    margin-bottom: 0.3em;

    @include lg-screen {
      font-size: 3em;
    }

    @include xl-screen {
      font-size: 3.2em;
    }
  }

  form {
    width: 90%;
    margin: 0 auto;

    @include md-screen {
      width: 70%;
    }

    @include lg-screen {
      width: 60%;
    }
  }

  .search-fields {
    @include md-screen {
      display: flex;
      justify-content: space-between;

      & > div {
        width: 47%;
      }
    }
  }

  .search-button {
    @include md-screen {
      text-align: right;
    }
  }

  .button {
    width: 100%;

    @include md-screen {
      width: auto;

      margin-bottom: 0em;
    }
  }
}
