@import "../../sass/colors";
@import "../../sass/helpers";
@import "../../sass/mixins";
@import "../../sass/layout";
@import "../../sass/typography";

header {
  .header {
    background-image: linear-gradient(
      to right,
      darken($color-primary, 20%),
      lighten($color-primary, 10%)
    );
    color: $color-white;
    padding: 0.25em 0.5em;

    @include md-screen {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @include lg-screen {
      padding: 0.5em 0.75em;
    }

    .title {
      font-size: 1.5em;

      @include lg-screen {
        font-size: 1.75em;
      }
    }
  }

  .badges {
    padding: 0.25em 0.5em;
    text-align: right;
    color: #926f34;
    font-family: $font-title;
    font-weight: bold;
    background-image: linear-gradient(
      to bottom right,
      #d2ac47,
      #f7ef8a,
      #d2ac47
    );
  }
}
