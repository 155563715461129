@import "../../sass/colors";
@import "../../sass/layout";

.time-line-item {
  border-bottom: 1px dashed $color-grey-light;
  padding: 0.75em 0;
  white-space: pre-wrap;

  @include md-screen {
    padding: 1em 0;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .title {
    h4,
    h3 {
      margin-bottom: 0;

      .icon {
        margin-right: 0.5em;
      }
    }
  }

  .dates {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include md-screen {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
    }

    .string {
      color: $color-text-secondary;
      font-size: 0.9em;
    }
  }
}
