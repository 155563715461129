@import "../../sass/colors";

.form-check {
  position: relative;

  margin-bottom: 0.25em;
  .check-disabled {
    color: $color-text-secondary;

    .icon {
      color: $color-text-secondary;
    }
  }

  .icon {
    margin-right: 0.5em;

    color: $color-primary;

    &:hover {
      cursor: pointer;
    }
  }

  input {
    position: absolute;

    width: 0;
    height: 0;

    cursor: pointer;

    opacity: 0;
  }

  small {
    display: block;
  }
}
