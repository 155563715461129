@import "../../sass/colors";
@import "../../sass/mixins";
@import "../../sass/layout";

.insert {
  position: relative;

  padding: 0.5em;

  background-color: $color-white;

  @include border-radius();

  @include md-screen {
    padding: 0.75em;
  }

  @include lg-screen {
    padding: 1em;
  }

  @include xl-screen {
    padding: 1.25em;
  }

  img {
    position: absolute;
    top: 2.5em;

    width: 6em;

    @include xl-screen {
      position: relative;
      top: auto;

      width: 100%;
    }
  }

  .title {
    margin-bottom: 0.5em;

    @include md-screen {
      margin-bottom: 0.75em;
    }
  }

  .content {
    margin-left: 6em;

    @include xl-screen {
      margin-left: 0;
    }
  }
}
