@import "../../sass/colors";

.login-register-modal {
  #card-card {
    padding: 0;
    border: 0;

    .icon.cross {
      display: none;
    }

    .content {
      padding: 0;
    }

    .tabs-container {
      .tabs-buttons {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $color-grey-light;

        button {
          width: 50%;
          margin: 0;
          border-radius: 0;
          border: none;
          font-size: 1.15em;
          padding-top: 0.5em;
          padding-bottom: 0.5em;
        }
      }
      .tabs-content {
        padding: 1em;
      }
    }
  }
}
